<template>
    <vx-card :title="'Work Order Execution - Complete'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Work Order ID</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="woCode" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Work Order Description</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="description" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Purpose</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="purpose" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Bill Of Material ID</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="bomCode" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="warehouseName" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Cost Center</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="costCenterName" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>

                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Request Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="requestDate" disabled>
                        </datepicker>
                    </div>
                </div>

                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Posting Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="postingDate" disabled>
                        </datepicker>
                    </div>
                </div>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <vs-divider style="width: 60%; margin-left: 30%">
                    List Attachment
                </vs-divider>
                <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile"
                                v-for="(tr) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.file_name }}</td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Show" v-if="tr.file_url != ''">
                                        <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                            @click.stop="handleShowAttachment(tr)" />
                                    </vx-tooltip>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>

        <h3><b>Parent (Sku Result)</b></h3>
        <hr />
        <br />
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="vertical-align: middle; width: 40%" rowspan="2">SKU Code</th>
                            <th style="vertical-align: middle; width: 7%" rowspan="2">HU</th>
                            <th style="width: 5%">Planned Qty</th>
                            <th style="width: 5%">Actual Qty</th>
                            <th style="width: 5%">Delta</th>
                            <th>Settlement</th>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                        </tr>
                        <tr>

                        </tr>
                    </thead>
                    <tbody>
                        <template>
                            <!-- item line -->
                            <tr style="padding-bottom: 5px;">
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="parentLines.sku_code + ' / ' + parentLines.item_name"
                                        :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="parentLines.unit" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input type="number" v-model="parentLines.quantity" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="parentLines.actual_qty" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input v-model="parentLines.delta_qty" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input v-model="parentLines.settlement" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input v-model="parentLines.storage_area" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input v-model="parentLines.batch_external" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="formatDate(parentLines.expired_date)" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <!-- <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="tr.putaway_lines.length"></td> -->

                                <!-- first line -->
                            </tr>

                            <!-- add lines -->
                            <!-- <template v-for="(trx, indexPutaway) in tr.putaway_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexPutaway"
                                    v-if="indexPutaway > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.storage_area" :readonly="true"
                                            style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.batch" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input :value="formatDate(trx.expired_date)" :readonly="true"
                                            style="width: auto;"></vs-input>
                                    </td>
                                </tr>
                            </template> -->
                        </template>
                    </tbody>
                </table>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="width: 10%">MAP</th>
                            <th style="width: 10%">MAP Execute</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input :value="parentLines.MAP" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="parentLines.lastMAP" disabled
                                    :style="{ backgroundColor: parentLines.MAP != parentLines.lastMAP ? 'yellow' : 'white' }"
                                    style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="width: 10%">Subtotal MAP</th>
                            <th style="width: 10%">Subtotal MAP Execute</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="parentLines.subTotalText" disabled style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="parentLines.subTotalLastMAPText" disabled
                                    style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <h3><b>Child (Sku Source)</b></h3>
        <hr />
        <br />
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="vertical-align: middle; width: 35%" rowspan="2">SKU Description</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">HU</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">Qty</th>
                            <th style="text-align: center;" colspan="3">Actual</th>
                        </tr>
                        <tr>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                            <th>MAP</th>
                            <th>MAP Execute</th>
                            <th>Subtotal MAP</th>
                            <th>Subtotal MAP Execute</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in skuLines">
                            <!-- item line -->
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.sku_code + ' / ' + tr.item_name" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>

                                <!-- first actual lines -->
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.actual_lines[0].storage_area" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.actual_lines[0].batch_number" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="formatDate(tr.actual_lines[0].expired_date)" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.MAP" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.lastMAP" disabled
                                        :style="{ backgroundColor: tr.MAP != tr.lastMAP ? 'yellow' : 'white' }"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.subTotalMAPText" disabled style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.subTotalTextLastMAP" disabled style="width: auto;"></vs-input>
                                </td>
                            </tr>

                            <!-- add actual line -->
                        </template>
                    </tbody>
                </table>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th>TOTAL</th>
                            <th>{{ totalMAP }}</th>
                            <th>{{ totalLastMAP }}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <!-- <h3 v-if="leftOverLines.length > 0">Leftover Items</h3>
        <hr><br> -->
        <!-- <div v-if="leftOverLines.length > 0" class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="vertical-align: middle; width: 10%" rowspan="2">SKU Code</th>
                            <th style="vertical-align: middle; width: 30%" rowspan="2">SKU Description</th>
                            <th style="vertical-align: middle; width: 7%" rowspan="2">HU</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">Qty</th>
                            <th style="padding: 5px; width: 2%" rowspan="2"></th>
                            <th style="text-align: center;" colspan="3">Put-away</th>
                        </tr>
                        <tr>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in leftOverLines">
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px;"
                                    :rowspan="tr.putaway_lines.length">
                                    <vs-input v-model="tr.sku_code" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;"
                                    :rowspan="tr.putaway_lines.length">
                                    <vs-input v-model="tr.item_name" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;"
                                    :rowspan="tr.putaway_lines.length">
                                    <vs-input v-model="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;"
                                    :rowspan="tr.putaway_lines.length">
                                    <vs-input v-model="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                    :rowspan="tr.putaway_lines.length"></td>

                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.putaway_lines[0].storage_area" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.putaway_lines[0].batch" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="formatDate(tr.putaway_lines[0].expired_date)" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                            </tr>

                            <template v-for="(trx, indexPutaway) in tr.putaway_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexPutaway"
                                    v-if="indexPutaway > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.storage_area" :readonly="true"
                                            style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.batch" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input :value="formatDate(trx.expired_date)" :readonly="true"
                                            style="width: auto;"></vs-input>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div> -->
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";


export default {
    components: {
        vSelect,
        Datepicker
    },
    data() {
        return {
            id: null,
            readonly: true,
            status: null,
            woCode: null,
            description: null,
            bomCode: null,
            warehouseID: 0,
            warehouseName: null,
            costCenterName: "",
            requestDate: null,
            settlement: "",
            postingDate: null,
            purpose: null,
            fileAttachment: [],
            parentLines: {
                sku_code: null,
                item_name: null,
                option_warehouse_area: [],
                option_batch: [],
                option_exp_date: [],
                unit: null,
                quantity: 0,
                actual_qty: 0,
                delta_qty: 0,
                storage_area: null,
                batch_external: null,
                expired_date: null,
                MAP: 0,
                lastMAP: 0,
                subTotal: 0,
                subTotalText: "0",
                subTotalLastMAP: 0,
                subTotalLastMAPText: "0",
                putaway_lines: [
                    {
                        putaway_id: 0,
                        storage_area: null,
                        batch: null,
                        expired_date: null,
                    }
                ]
            },
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    unit: null,
                    qty: 0,
                    MAP: 0,
                    lastMAP: 0,
                    subTotal: 0,
                    subTotalText: "0",
                    subTotalLastMAP: 0,
                    subTotalTextLastMAP: "0",
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            storage_area: null,
                            batch_number: null,
                            expired_date: null,
                        },
                    ],
                }
            ],
            totalMAP: 0,
            totalLastMAP: 0,
            leftOverLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    option_warehouse_area: [],
                    option_batch: [],
                    option_exp_date: [],
                    unit: null,
                    qty: 0,
                    putaway_lines: [
                        {
                            putaway_id: 0,
                            storage_area: null,
                            batch: null,
                            expired_date: null,
                        }
                    ]
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/work-order-execution/putaway-form/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.woCode = resp.data.wo_code
                        this.description = resp.data.wo_description
                        this.bomCode = resp.data.bom_code
                        this.warehouseID = resp.data.warehouse_id
                        this.warehouseName = resp.data.warehouse_name
                        this.costCenterName = `${resp.data.cost_center_code} - ${resp.data.cost_center_name}`
                        this.postingDate = resp.data.posting_date
                        this.fileAttachment = resp.data.attachments.map(attachment => ({
                            file_name: attachment.name,
                            file_url: attachment.url
                        }))
                        this.parentLines = resp.data.parent_lines
                        this.parentLines.MAP = resp.data.parent_lines.price
                        this.parentLines.lastMAP = resp.data.parent_lines.actual_price.toFixed(7)

                        const currUOM = resp.data.parent_lines.qty_uom
                        const currMAP = resp.data.parent_lines.MAP
                        const currLastMAP = resp.data.parent_lines.lastMAP
                        const currQTY = resp.data.parent_lines.actual_qty

                        const subTotal = currUOM * currMAP * currQTY
                        const subTotalLastMAP = currUOM * currLastMAP * currQTY

                        const subTotalMAPText = new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 7,
                            maximumFractionDigits: 7,
                        }).format(subTotal)

                        const subTotalLastMAPText = new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 7,
                            maximumFractionDigits: 7,
                        }).format(subTotalLastMAP)

                        this.parentLines.subTotal = subTotal
                        this.parentLines.subTotalText = subTotalMAPText
                        this.parentLines.subTotalLastMAP = subTotalLastMAP
                        this.parentLines.subTotalLastMAPText = subTotalLastMAPText

                        this.skuLines = resp.data.sku_lines.map(item => {
                            const currUOM = item.qty_uom
                            const currMAP = item.price
                            const currLastMAP = item.actual_price
                            const currQTY = item.qty

                            const subTotal = currUOM * currMAP * currQTY
                            const subTotalLastMAP = currUOM * currLastMAP * currQTY

                            const subTotalMAPText = new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 7,
                                maximumFractionDigits: 7,
                            }).format(subTotal)

                            const subTotalLastMAPText = new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 7,
                                maximumFractionDigits: 7,
                            }).format(subTotalLastMAP)
                            return {
                                ...item,
                                MAP: item.price,
                                lastMAP: item.actual_price.toFixed(7),
                                subTotal: subTotal,
                                subTotalLastMAP: subTotalLastMAP,
                                subTotalMAPText: subTotalMAPText,
                                subTotalTextLastMAP: subTotalLastMAPText,
                            }

                        })

                        const arrOfSubTotal = this.skuLines.map(item => item.qty * item.qty_uom * item.MAP)
                        const total = arrOfSubTotal.reduce((a, b) => a + b, 0)
                        const totalText = new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 7,
                            maximumFractionDigits: 7,
                        }).format(total)

                        const arrOfSubTotalLastMAP = this.skuLines.map(item => item.qty * item.qty_uom * item.lastMAP)
                        const totalLastMap = arrOfSubTotalLastMAP.reduce((a, b) => a + b, 0)
                        const totalLastMapText = new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 7,
                            maximumFractionDigits: 7,
                        }).format(totalLastMap)

                        this.totalMAP = totalText
                        this.totalLastMAP = totalLastMapText
                        this.requestDate = resp.data.wo_request_date

                        this.purpose = resp.data.purpose_name
                        this.parentLines.delta_qty = this.parentLines.quantity - this.parentLines.actual_qty
                        // this.leftOverLines = resp.data.leftover_lines
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            window.history.back();
        },
        handleShowAttachment(file) {
            let objectURL;
            // console.log(file)
            objectURL = file.file_url;

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>
